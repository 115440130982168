require("./jquery.fancybox.min.js")
require("./jquery_elastic.js");

require("./action_list.js");
require("./reply_to.js");
require("./verify_coupon.js");
require("./submit_user_order.js");
//require("./remember_sidebar_scroll.js");
require("./hotkeys.js");
require("./cable_ready_events.js");
require("./custom.js");
require("./table_cell_hover.js");

//require("./toggle_flatron_group_list.js");
//require("./toggle_flatron_follow_list.js");
//require("./toggle_flatron_shop_list.js");
//require("./toggle_sidebar.js");
//require("./infinite_scroll.js");
//require("./sticky_block.js");

require("./toggle_group_list.js");
require("./toggle_shop_list.js");
require("./toggle_follow_list.js");
require("./toggle_shop_list_bar.js");
require("./toggle_group_list_bar.js");

//require("./resize_sensor.js");
require("./jquery.sticky-sidebar.min.js");
require("./sticky_sidebar.js");

//require("./feeds_scroll.js");
require("./back_to_top.js");

//require("./load_more.js");
//require("./load_less.js");
//require("./site_block_ajax.js");

require("./social_share_buttons.js")
require("./clipboard.js")

require("./countdown.js")
require("./dropzone.js")
//require("./tippy_things.js") // 这里调用出错了
