const toggleMenu = require("./toggle_menu.js")

document.addEventListener('turbolinks:load', function() {
    if ($("#sidebar").length == 0) {
        return
    }

    toggleMenu(
        "follow-stackmenu-status",
        "#sidebar .follow-openstack",
        "#sidebar .follow-closestack",
        "#sidebar .follow-stackmenu",
        "hide"
    )
});