document.addEventListener('turbolinks:load', function() {

  $("body").on("click", ".reply-to-author", function() {
    var author = $(this).data('author');
    var comment_id = $(this).data('comment-id');

    var form = $("#comment-box-form");
    form.find(".reply-to-id").val(comment_id);
    form.find(".reply-author-name").html('@' + author);
    form.find(".clear-reply-to").show();

    form.find("#comment-box").focus();
  });

  $("body").on("click", ".clear-reply-to", function() {
    var form = $(this).parents("#comment-box-form");
    form.find(".reply-to-id").val(0);
    form.find(".reply-author-name").html("");
    $(this).hide();
  });

});
