window.$ = $;
window.jQuery = $;
window.geekhub = {};

document.addEventListener('turbolinks:load', function() {
  $(".custom-file-input").on("change", function() {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
  });

  window.geekhub.sortable = function(selector, update_path) {
    var options = {
      helper: function(e, ui) {
        ui.children().each(function() {
          $(this).width($(this).width());
        });
        return ui;
      }
    };
    var settings = {
      stop: function(event, ui) {
        $.post(update_path,
          $(this).sortable('serialize', {key: 'position[]'}),
          function() {
            $(ui.item).parent().effect('highlight');
          })
      }
    }
    $.extend(settings, options);
    $(selector).sortable(settings);
  }

  window.setTimeout(function() {
    $(".alert").fadeTo(500, 0).slideUp(500, function(){
      $(this).remove();
    });
  }, 4000);

  $("textarea").elastic();
});

