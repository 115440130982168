document.addEventListener('turbolinks:load', function() {

    $('td').hover(function() {
        var $currentTable = $(this).closest('table');
        var allCells = $currentTable.find("td");

        allCells
            .on("mouseover", function() {
                var el = $(this),
                    pos = el.index();
                el.parent().find("td").addClass("isHovered");
                allCells.filter(":nth-child(" + (pos+1) + ")").addClass("isHovered");
            })
            .on("mouseout", function() {
                allCells.removeClass("isHovered");
            });
    });

});