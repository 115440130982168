const toggleMenu = require("./toggle_menu.js")

document.addEventListener('turbolinks:load', function() {
    if ($("#float-bar").length == 0) {
        return
    }

    toggleMenu(
        "shops-stackmenu-status",
        "#float-bar .shops-openstack",
        "#float-bar .shops-closestack",
        "#float-bar .shops-stackmenu",
        "hide"
    )
});