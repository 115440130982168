document.addEventListener('turbolinks:load', function() {

  $("#submit-user-order").click(function() {
    var el = $(".verify-coupon");

    if (el.length == 0) {
      return true;
    }

    var run = el.data('run');
    var coupon = $("#user_order_coupon").val().trim();

    if (coupon.length == 0) {
      return true;
    }

    if (run != "true") {
      alert("请验证优惠码");
      return false;
    }

    return true;
  });

});
