
(function() {
  var Mustache = require('mustache');

  const highlight = (target) => {
    $(target).effect('highlight', {color: 'rgba(251, 211, 141, .6)'})
  }

  $(document).on("highlight", function(ev) {
    highlight(ev.target)
  })

  $(document).on("bump_to_top", function(ev) {
    $("#home-feed-list").prepend($(ev.target))
    highlight(ev.target)

    setTimeout(() => {
      $(ev.target)[0].outerHTML = ev.detail
    }, 400)
  })

  $(document).on("flatron_bump_to_top", function(ev) {
    const container_id = '#' + $(ev.target).data('site-block-css-id')
    const container = $(container_id)

    container.prepend($(ev.target))
    highlight(ev.target)
  })

  $(document).on("restart_timer", function(ev) {
    $(ev.target).data('seconds', ev.detail)
    restart_countdown()
  })

  $(document).on("site_notice_alert", function(ev) {
    var data = JSON.parse(ev.detail);
    var css_id = "#site-notice-alert";

    var output = Mustache.render($(css_id).html(), data)
    $("#site-alert-area").append(output);
  })

  $(document).on("test_cable", function() {
    alert('cable test');
  });
})();
