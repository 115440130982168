import hotkeys from 'hotkeys-js';

hotkeys.filter = function(event) {
  return true;
}

hotkeys("command+enter, ctrl+enter", function() {
  var form = $("#comment-box-form")[0]
  $.rails.fire(form, 'submit');
});

hotkeys("option+a, alt+a", function() {
  window.location.href = "/";
});

hotkeys("option+w, alt+w", function() {
  window.location.href = "/posts/new";
});

hotkeys("option+e, alt+e", function() {
  window.location.href = "/activities";
});

hotkeys("option+g, alt+g", function() {
  window.location.href = "/gbit_store";
});

hotkeys("option+h, alt+h", function() {
  window.location.href = "/admin";
});

hotkeys("option+t, alt+t", function() {
  window.location.href = "/admin/tickets";
});


hotkeys("option+b, alt+b", function() {
  if ($("body").hasClass("flatron")) {
    $(".main-col .h-screen").scrollTop(1000000)
  } else {
    $(window).scrollTop(1000000)
  }
});

hotkeys("option+v, alt+v", function() {
  if ($("body").hasClass("flatron")) {
    $(".main-col .h-screen").scrollTop(0)
  } else {
    $(window).scrollTop(0)
  }
});

