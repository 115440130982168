module.exports = function(key, openBtn, closeBtn, stackMenu, initStatus, callback) {
  const openButton = $(openBtn)
  const closeButton = $(closeBtn)
  const stackMenuList = $(stackMenu)

  const noOp = function() {}
  if (callback == undefined) {
    callback = {}
  }

  const onMenuOpen = callback['onMenuOpen'] || noOp
  const onMenuClose = callback['onMenuClose'] || noOp

  const showStackMenu = function() {
    stackMenuList.show()
    openButton.hide()
    closeButton.show()
  }

  const hideStackMenu = function() {
    stackMenuList.hide()
    openButton.show()
    closeButton.hide()
  }

  openButton.click(function(ev) {
    ev.preventDefault()

    showStackMenu()
    onMenuOpen()

    localStorage.setItem(key, "show")
  })

  closeButton.click(function(ev) {
    ev.preventDefault()

    hideStackMenu()
    onMenuClose()

    localStorage.setItem(key, "hide")
  })


  const status = localStorage.getItem(key) || initStatus
  if (status == "hide") {
    hideStackMenu()
  } else {
    showStackMenu()
  }
}
