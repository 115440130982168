require("base")

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("turbolinks").start()

require("jquery");
require("jquery-ui/ui/effects/effect-highlight");
require("jquery-ui/ui/widgets/sortable");

require("alpine-turbolinks-adapter");
require("alpinejs");
require("balloon-css");

require("channels");

// import { Application } from "stimulus"
// import Prefetch from "stimulus-prefetch"
//
// const application = Application.start();
// application.register("prefetch", Prefetch);

require("stylesheets/application");
require("stylesheets/custom");
