(function() {
  function ndesc(n, desc) {
    if (n >= 0) {
        return ' ' + n.toString() + ' ' + desc;
    } else {
        return " ";
    }
  }


  function show_countdown(id, seconds) {
    if (seconds < 0) {
      seconds = 0;
      clearInterval(window.countdown_timer);
      window.location.reload();

      return;
    }

    var day = Math.floor(seconds / 86400)
    var hour = Math.floor(seconds % 86400 / 3600);
    var minutes = Math.floor(seconds % 3600 / 60);
    var _seconds = Math.floor(seconds % 3600 % 60);

    var _html = "";

    if (day > 0) {
      _html += ndesc(day, '<span class="text-sm">天</span>');
    }

    if (hour > 0) {
      _html += ndesc(hour, '<span class="text-sm">小时</span>');
    }

    _html += ndesc(minutes, '<span class="text-sm">分钟</span>') +
      ndesc(_seconds, '<span class="text-sm">秒</span>')

    $(id).html(_html)
  }

  window.restart_countdown = function() {
    clearInterval(window.countdown_timer)

    var seconds = parseInt($("#count-down").data("seconds"))
    if (seconds > 0) {
        window.countdown_timer = setInterval(function() {
            seconds = seconds - 1;
            show_countdown('#count-down', seconds);
        }, 1000)
    } else {
        $("#count-down").html("0 小时 0 分钟 0 秒");
    }
  }

  document.addEventListener("turbolinks:load", function() {
    restart_countdown()
  });
})()
