const toggleMenu = require("./toggle_menu.js")

document.addEventListener('turbolinks:load', function() {
  if ($("#sidebar").length == 0) {
    return
  }

  toggleMenu(
    "shops-stackmenu-status",
    "#sidebar .shops-openstack",
    "#sidebar .shops-closestack",
    "#sidebar .shops-stackmenu",
    "hide"
  )
});
