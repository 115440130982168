document.addEventListener('turbolinks:load', function() {

  $(".verify-coupon").click(function() {
    var that = $(this);
    var coupon = $("#user_order_coupon").val().trim();
    var product_id = $(this).data('product-id');
    var attr_key = $(this).data('attr-key');

    if (coupon.length == 0) {
      that.data('run', 'true');
      return false;
    }

    $.get("/coupons/verify", {name: coupon, product_id: product_id, attr_key: attr_key}, function(result) {
      alert(result.msg);
      that.data('run', 'true');
    });
  });

});
