const toggleMenu = require("./toggle_menu.js")

document.addEventListener('turbolinks:load', function() {
    if ($("#float-bar").length == 0) {
        return
    }

    toggleMenu(
        "groups-stackmenu-status",
        "#float-bar .groups-openstack",
        "#float-bar .groups-closestack",
        "#float-bar .groups-stackmenu",
        "hide"
    )
});